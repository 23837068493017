import * as React from 'react'
import LoginIcon from '@mui/icons-material/Login'

const NAVIGATION_UNAUTHENTICATED = [
  {
    segment: '/authentication/login',
    title: 'Log ind',
    icon: <LoginIcon />
  }

]

export default NAVIGATION_UNAUTHENTICATED
