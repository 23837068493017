/* eslint-disable max-len */
/* eslint-disable require-jsdoc */
import React, { useEffect, useState } from 'react'
import AppRoutes from './AppRoutes'
import authService from './components/api-authorization/AuthorizeService'
// import { Layout } from './components/Layout'
import './custom.css'
import DashboardLayoutBasic from './components/dashboard/DashboardLayoutBasic'
import ReactGA from 'react-ga4'
import RouteChangeTracker from './components/RouteChangeTracker'

const App = () => {
  ReactGA.initialize('G-WSQHC5C2E3')
  const [userRole, setUserRole] = useState(null)

  useEffect(() => {
    const fetchUserRole = async () => {
      authService.ensureUserManagerInitialized()
      const isAuthenticated = await authService.isAuthenticated()
      if (isAuthenticated) {
        const user = await authService.getUser()
        setUserRole(user.role)
        ReactGA.set({
          user_id: user.sub,
          user_role: user.role
        })
      }
    }
    fetchUserRole()
  }, [])

  return (
    <DashboardLayoutBasic>
      <RouteChangeTracker />
      <AppRoutes userRole={userRole} />
    </DashboardLayoutBasic>
  )
}

export default App
