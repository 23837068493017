/* eslint-disable react/prop-types */
import React, { useEffect, useState, Suspense } from 'react'
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute'
import { Routes, Route } from 'react-router-dom'

const AppRoutes = ({ userRole }) => {
  const [RoutesConfig, setRoutesConfig] = useState([])

  useEffect(() => {
    const loadRoutes = async () => {
      let routes = []
      switch (userRole) {
      case 'Default':
        routes = (await import('./routes/DefaultAppRoutes')).default
        break
      case 'Administrator':
        routes = (await import('./routes/AdminAppRoutes')).default
        break
      case 'PortfolioManager':
        routes = (await import('./routes/PortfolioManagerAppRoutes')).default
        break
      case 'RelationshipManager':
        routes = (await import('./routes/PortfolioManagerAppRoutes')).default
        break
      default:
        routes = (await import('./routes/BasicAppRoutes')).default
        break
      }
      setRoutesConfig(routes)
    }
    loadRoutes()
  }, [userRole])

  return (
    <Suspense fallback={<></>}>
      <Routes>
        {RoutesConfig.map((route, index) => {
          const { element, requireAuth, roles, ...rest } = route
          return (
            <Route
              key={index}
              {...rest}
              element={
                requireAuth
                  ? (
                    <AuthorizeRoute {...rest} roles={roles} element={element} />
                  )
                  : (
                    element
                  )
              }
            />
          )
        })}
      </Routes>
    </Suspense>
  )
}

export default AppRoutes
