/* eslint-disable react/prop-types */
import React, { createContext, useState } from 'react'
import { Loader } from '../components/Loader'

export const LoaderContext = createContext(undefined)

export const LoaderProvider = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false)
  const [loaderMessage, setLoaderMessage] = useState(undefined)

  const contextValue = {
    showLoader: (message) => {
      setLoaderMessage(message)
      setIsVisible(true)
    },
    hideLoader: () => {
      setIsVisible(false)
    }
  }

  return (
    <LoaderContext.Provider value={contextValue}>
      {isVisible && <Loader message={loaderMessage} />}
      {children}
    </LoaderContext.Provider>
  )
}
