import * as React from 'react'
import ListIcon from '@mui/icons-material/List'
import SourceIcon from '@mui/icons-material/Source'
import BarChartIcon from '@mui/icons-material/BarChart'

const NAVIGATION_DEFAULT = [
  {
    kind: 'header',
    title: 'Hjem'
  },
  {
    segment: '',
    title: 'Min portefølje',
    icon: <BarChartIcon />
  },
  {
    segment: 'Transaction',
    title: 'Transaktioner',
    icon: <ListIcon />
  },
  {
    segment: 'Document',
    title: 'Dokumenter',
    icon: <SourceIcon />
  }
]

export default NAVIGATION_DEFAULT
